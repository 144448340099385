bindEvent(document.getElementById('mobile-menu'), 'click', mobileMenuToggler);

var personalize = document.querySelectorAll('.personalized');

for (var i=0; i<personalize.length; i++) {
	bindEvent(personalize[i], 'click', showPersonalizationForm);
}

var share = document.querySelectorAll('.btn-share');
for (var i=0; i<share.length; i++) {
	if (share[i].id != 'share-email') {
		bindEvent(share[i], 'click', doShare);
	}
}

showMobileShareLinks();

function showMobileShareLinks() {
  var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;  

  if (width > 990) {
    return;
  }

	var container = document.createElement('div');
	container.id = 'footer-share';
	document.body.appendChild(container);

	for (var i=0; i<share.length; i++) {
		container.appendChild(share[i]);
	}

	document.getElementById('footer-message').style.display = 'none';
}


/*
TODO: Get app links working on mobile?
setTimeout(function () { window.location = "https://itunes.apple.com/appdir"; }, 25);
window.location = "appname://";

http://stackoverflow.com/questions/13675535/how-to-launch-apps-facebook-twitter-etc-from-mobile-browser-but-fall-back-to-h
http://stackoverflow.com/questions/11710902/can-i-make-a-link-that-will-open-in-my-app-if-its-installed-and-fall-back-to-a
https://gist.github.com/ChaseFlorell/5119047

  function doCallback() {
    var user = $(this).data('user');
    var text = "\"" + ($(this).data('message') || $(this).text()) + "\" " + window.location.href + (user ? " via @" + $(this).data('user') : '');
    var original_start = new Date();
    var lag = 1250;

    var deeplink_url = "twitter://post?message=" + encodeURIComponent(text);
    window.location.href = deeplink_url;

    setTimeout(function() {
      var time_spent_waiting = (new Date() - original_start);
      if (time_spent_waiting > (2.0 * lag)) {
        // We can assume they have the app, so do nothing.
      } else {
        // That was too fast so we can assume they don't have the app.
        var intent_url = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(text);
        window.open(intent_url, "_blank");
      }
    }, lag);
    return false;
  }

  $(".tweetable").on("click", doCallback);
});
*/

function showPersonalizationForm() {
	var html = '';
	html += '<h4 class="title">Fill in your names<span class="hide-mobile-sm"> to personalize the vows</span>:</h4>';

	var personalization = getCookie('personalization');

	if (personalization) {
		personalization = personalization.split('|');
		var bride_or_groom = personalization[0];
		if (bride_or_groom == 'bride') {
			var bride_first = personalization[1];
			var bride_last  = personalization[2];
			var groom_first = personalization[3];
			var groom_last  = personalization[4];
		} else {
			var bride_first = personalization[3];
			var bride_last  = personalization[4];
			var groom_first = personalization[1];
			var groom_last  = personalization[2];
		}
	} else {
		var groom_first = groom_last = bride_first = bride_last = '';
		var bride_or_groom = 'bride';
	}

	html += '<form id="vow-personalization-form">';
	html += '<div class="center table">';
	html += '<div class="row"><div class="cell">Groom: </div><div class="cell"><input type="text" name="groom_first" id="groom-first" class="form-input" placeholder="First Name" value="' + groom_first + '" /><input type="text" name="groom_last" id="groom-last" class="form-input" placeholder="Last Name" value="' + groom_last + '" /></div></div>';
	html += '<div class="row"><div class="cell">Bride: </div><div class="cell"><input type="text" name="bride_first" id="bride-first" class="form-input" placeholder="First Name" value="' + bride_first + '" /><input type="text" name="bride_last" id="bride-last" class="form-input" placeholder="Last Name" value="' + bride_last + '" /></div></div>';
	html += '<div class="row"><div class="cell">You<span class="hide-mobile-sm"> are</span>: </div><div class="cell"><select name="bride_or_groom" id="bride-or-groom" class="form-input"><option value="bride"' + (bride_or_groom == 'bride' ? ' selected="selected"' : '') + '>The bride</option><option value="groom"' + (bride_or_groom == 'groom' ? ' selected="selected"' : '') + '>The groom</option></select></div></div>';
	html += '<div class="row"><div class="cell">&nbsp;</div><div class="cell"><button type="submit" class="btn btn-prime btn-sm" style="width:100%;margin-top:5px">Personalize</button></div></div>';
	html += '</div>';
	html += '</form>';

	showModal(html);
	
	bindEvent(document.getElementById('vow-personalization-form'), 'submit', doPersonalization);
}

function doPersonalization(e) {
	preventEventDefault(e);

	var groom_first    = document.getElementById('groom-first').value;
	var groom_last     = document.getElementById('groom-last').value;
	var bride_first    = document.getElementById('bride-first').value;
	var bride_last     = document.getElementById('bride-last').value;
	var who 		       = document.getElementById('bride-or-groom').value;

  if (groom_first == 'First Name') {
    groom_first = '';
  }
  if (groom_last == 'Last Name') {
    groom_last = '';
  }
  if (bride_first == 'First Name') {
    bride_first = '';
  }
  if (bride_last == 'Last Name') {
    bride_last = '';
  }
  
	var defaults = {
		wha  : 'husband/wife',
		whb  : 'husband/wife',
		whag : 'him/her',
		whbg : 'him/her',
		wham : 'man/woman',
		whbm : 'man/woman',
		whap : 'father/mother',
		whbp : 'father/mother',
		whaf : 'boyfriend/girlfriend',
		whbf : 'boyfriend/girlfriend',
		x    : 'X',
		xth  : 'Xth',
		date : '(month, day, year)'
	};

	if (who == 'bride') {
		var data = {
			af   : bride_first,
			wf   : bride_first,
			al   : bride_last,
			wl   : bride_last,
			bf   : groom_first,
			mf   : groom_first,
			bl   : groom_last,
			ml   : groom_last,
			wha  : 'wife',
			whb  : 'husband',
			whag : 'her',
			whbg : 'him',
			wham : 'woman',
			whbm : 'man',
			whap : 'mother',
			whbp : 'father',
			whaf : 'girlfriend',
			whbf : 'boyfriend'
		};
	} else {
		var data = {
			af   : groom_first,
			mf   : groom_first,
			al   : groom_last,
			ml   : groom_last,
			bf   : bride_first,
			wf   : bride_first,
			bl   : bride_last,
			wl   : bride_last,
			wha  : 'husband',
			whb  : 'wife',
			whag : 'him',
			whbg : 'her',
			wham : 'man',
			whbm : 'woman',
			whap : 'mother',
			whbp : 'father',
			whaf : 'boyfriend',
			whbf : 'girlfriend'
		}
	}

	data.a = (data.af && data.al ? data.af + ' ' + data.al : (data.af ? data.af : (data.al ? data.al : '')));
	data.b = (data.bf && data.bl ? data.bf + ' ' + data.bl : (data.bf ? data.bf : (data.bl ? data.bl : '')));
	data.m = (data.mf && data.ml ? data.mf + ' ' + data.ml : (data.mf ? data.mf : (data.ml ? data.ml : '')));
	data.w = (data.wf && data.wl ? data.wf + ' ' + data.wl : (data.wf ? data.wf : (data.wl ? data.wl : '')));

	setCookie('personalization', who + '|' + data.af + '|' + data.al + '|' + data.bf + '|' + data.bl, 365);

	var links = document.querySelectorAll('.personalized');

	for (var i=0; i<links.length; i++) {
		var link = links[i];
		var key  = link.className.toLowerCase().replace(/_/g, '').replace('personalized ', '');

		if (data.hasOwnProperty(key)) {
			link.innerHTML = (data[key] ? data[key] : '________');
		} else if (defaults.hasOwnProperty(key)) {
			link.innerHTML = defaults[key];
		} else {
			link.innerHTML = '________';
		}
	}

	removeModal();
}

function mobileMenuToggler(e) {
	preventEventDefault(e);

	var mobileNav = document.getElementById('mobile-nav');

	if (!mobileNav) {
		mobileNav = document.createElement('ul');
		mobileNav.id = 'mobile-nav';
		var docFrag = document.createDocumentFragment();
		var categories = ['vows', 'ceremony', 'bible', 'poems', 'articles', 'other'];
		for (var i=0; i<categories.length; i++) {
			var list = document.getElementById('sidebar-' + categories[i]).getElementsByTagName('li');
			for (var j=0; j<list.length; j++) {
				docFrag.appendChild(list[j].cloneNode(true));
			}
		}
		mobileNav.appendChild(docFrag);
		document.getElementById('mobile-nav-container').appendChild(mobileNav);
	}
	
	if (mobileNav.style.display == 'block') {
		mobileNav.style.display = 'none';
		document.getElementById('header').className = '';
	} else {
		mobileNav.style.display = 'block';
		document.getElementById('header').className = 'nav-open';
	}
}

function doShare(e) {
    preventEventDefault(e);
	var el = (e.target || e.srcElement);
	openURLInPopup(el.getAttribute('href'));
}

function openURLInPopup(url, w, h, name) {
	if (typeof(w) == 'undefined') {
        w = 600;
        h = 300;
    }
    if (typeof(h) == 'undefined') {
        h = 300;
    }

    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX != undefined ? window.screenX : screen.left;  
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY != undefined ? window.screenY : screen.top;  
        
    width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;  
    height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;  


    var left = ((width / 2) - (w / 2)) + dualScreenLeft;  
    var top = ((height / 2) - (h / 2)) + dualScreenTop; 

    var newWindow = window.open(url, name || 'window' + Math.floor(Math.random() * 10000 + 1), 'width=' + w + ', height=' + h + ', top=' + top + ', left = ' + left + ', menubar=0, location=0, toolbar=0, status=0, scrollbars=0');
  
    if (window.focus) {  
        newWindow.focus();  
    }  
}

function showModal(content) {
	var modal = document.createElement('div');
	modal.id = 'modal';
	modal.className = 'modal';
	document.body.appendChild(modal);

	modal.innerHTML = '<button id="modal-close" class="modal-close">×</button><div id="modal-content" class="modal-content"></div>';

	var modalCloseButton = document.getElementById('modal-close');
	var modalContent = document.getElementById('modal-content');

	modalContent.innerHTML = content; //+ '<button class="modal-close2">x</button>';

	modal.style.display = 'block';
    
    var modalHeight = Math.max(modalContent.offsetHeight, modalContent.scrollHeight, modalContent.clientHeight || 0);
    var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    if (modalHeight < viewportHeight) {
    	modalContent.className += ' modal-center';
    }

    window.setTimeout(function() {
    	modal.className += ' modal-visible';
    }, 50);

    bindEvent(modalCloseButton, 'click', removeModal);
    bindEvent(modal, 'click', removeModal);
    bindEvent(modalContent, 'click', stopEventPropagation);
}

function removeModal() {
	var modal = document.getElementById('modal');
	modal.parentNode.removeChild(modal);
}

function setCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        var expires = "; expires="+date.toUTCString();
    } else {
    	var expires = "";
    }

    document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i=0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
        	c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
        	return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function deleteCookie(name) {
    setCookie(name, "", -1);
}

function bindEvent(el, eventName, fn) {
	if (el.addEventListener) {
		el.addEventListener(eventName, fn);
	} else {
		el.attachEvent('on' + eventName, fn);
	}
}

function unbindEvent(el, eventName, fn) {
	if (el.removeEventListener) {
		el.removeEventListener(eventName, fn);
	} else {
		el.detachEvent('on' + eventName, fn);
	}
}

function preventEventDefault(e) {
	if (!e) {
		var e = window.event;
	}

	if (e.preventDefault) {
		e.preventDefault();
	} else {
		e.returnValue = false;
	}
}

function stopEventPropagation(e) {
	if (!e) {
		var e = window.event;
	}

	if (e.stopPropagation) {
		e.stopPropagation();
	} else {
		e.cancelBubble = true;
	}
}